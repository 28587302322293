<template>
    <b-row>
      <b-col lg="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Packges / Services</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else striped responsive :fields="columns" :items="getAllPackageOrService" class="mb-0 table-borderless">
              <template v-slot:cell(source)="data">
                {{data.item.source.title}}
              </template>
              <template v-slot:cell(action)="data1">
                <router-link v-if="data1.item.type == 'package'" :to="{name:'addPackage', query:{packageId :data1.item.id, edit: true}}"><b-button variant=" iq-bg-primary mr-0 mb-0" size="sm"><i class="las la-eye"></i></b-button></router-link>
                <router-link v-else :to="{name:'newservices', query:{serviceId :data1.item.id, edit: true}}"><b-button variant=" iq-bg-primary mr-0 mb-0" size="sm"><i class="las la-eye"></i></b-button></router-link>
              </template>
            </b-table>
            <b-pagination v-model="currentPage"
                          class="mt-3"
                          :total-rows="total"
                          align="right"
                          :per-page="per_page"
                          aria-controls="my-table"
                          @input="changeContent"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dashboard2.summary')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-dropdown id="dropdownMenuButton5" right variant="none" data-toggle="dropdown">
              <template v-slot:button-content>
                <span class="text-primary"><i class="ri-more-2-fill"></i></span>
              </template>
              <b-dropdown-item><i class="ri-eye-fill mr-2"></i>{{ $t('dropdown.view') }}</b-dropdown-item>
              <b-dropdown-item><i class="ri-delete-bin-6-fill mr-2"></i>{{ $t('dropdown.delete') }}</b-dropdown-item>
              <b-dropdown-item><i class="ri-pencil-fill mr-2"></i>{{ $t('dropdown.edit') }}</b-dropdown-item>
              <b-dropdown-item><i class="ri-printer-fill mr-2"></i>{{ $t('dropdown.print') }}</b-dropdown-item>
              <b-dropdown-item><i class="ri-file-download-fill mr-2"></i>{{ $t('dropdown.download') }}</b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-slot:body>
            <p class="mb-0">{{ $t('dashboard2.totalLikeComment')}}</p>
            <div class="m-0 p-0 d-flex flex-wrap align-items-center justify-content-between">
              <div class="col-md-6">
                <div class="d-flex align-items-center  mt-4">
                  <div class="iq-scale-border mr-3 like-block"></div>
                  <div class="iq-scale-content">
                    <h6>2.864</h6>
                    <p class="mb-0">Likes</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center  mt-4">
                  <div class="iq-scale-border mr-3 comment-block"></div>
                  <div class="iq-scale-content">
                    <h6>624</h6>
                    <p class="mb-0">Comments</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 share-block"></div>
                  <div class="iq-scale-content">
                    <h6>1.75</h6>
                    <p class="mb-0">Share</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 repost-block"></div>
                  <div class="iq-scale-content">
                    <h6>345</h6>
                    <p class="mb-0">Repost</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 view-block"></div>
                  <div class="iq-scale-content">
                    <h6>845</h6>
                    <p class="mb-0">Views</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 post-block"></div>
                  <div class="iq-scale-content">
                    <h6>1200</h6>
                    <p class="mb-0">Posts</p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
</template>
<script>
import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
const addLeadModel = () => import('../../components/leads/leadsModal')
export default {
  name: 'package',
  data () {
    return {
      currentPage: 1,
      per_page: 10,
      total: 6,
      modelTitle: 'Add Lead',
      editFlag: false,
      loadingOpenAdd: false,
      loadTable: true,
      columns: [
        { label: 'Name', key: 'title', class: 'left' },
        { label: 'Period', key: 'period', class: 'left' },
        { label: 'Price', key: 'pivot', class: 'left' },
        { label: 'Active Member', key: 'active_member', class: 'left' },
        { label: 'Actions', key: 'action', class: 'left' }
      ],
      allFeatures: {}
    }
  },
  computed: {
    ...mapGetters(['getAllPackageOrService'])
  },
  methods: {
    changeContent () {
      this.fillTable()
    },
    loadData () {
      this.loadingOpenAdd = true
      this.editFlag = false
      this.modelTitle = 'Add Lead'
      this.$store.dispatch('getInfoToCreateLead').then((data) => {
        this.$bvModal.show('modal-all-lead')
        console.log(data.data)
        this.allFeatures = data.data.data
        this.loadingOpenAdd = false
      })
    },
    fillTable () {
      this.loadTable = true
      this.$store.dispatch('getAllPackageOrService', { type: '', pageNumber: this.currentPage }).then(data => {
        this.currentPage = data.data.meta.current_page
        this.per_page = data.data.meta.per_page
        this.total = data.data.meta.total
        this.loadTable = false
      })
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    addLeadModel
  },
  mounted () {
    core.index()
  },
  created () {
    this.fillTable()
  }
}
</script>
<style scoped>
.colorSpan{
  width: 20px;
  height: 20px;
}
</style>
